.parallax-bg {
  position: relative;
  background-attachment: fixed;
  background-size: cover;
  will-change: background-position;
  transform: translateZ(0); }
  .parallax-bg .form-control {
    height: auto; }

.brk-paraxify {
  position: relative;
  background: fixed center center;
  background-size: cover !important; }
  .brk-paraxify .form-control {
    height: auto; }

.corner__wrap,
.circle__wrap,
.triangle__wrap,
.round__wrap,
.wing__wrap {
  position: relative;
  z-index: 1;
  transform: translate(50%, 0);
  transition: transform 2s ease;
  will-change: transform; }
  .corner__wrap .parallax__bg-shape-lg,
  .circle__wrap .parallax__bg-shape-lg,
  .triangle__wrap .parallax__bg-shape-lg,
  .round__wrap .parallax__bg-shape-lg,
  .wing__wrap .parallax__bg-shape-lg {
    position: absolute;
    z-index: -1;
    top: -1px;
    bottom: -1px;
    -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3)); }
  .corner__wrap .parallax__bg-shape-sm.brk-parallax__bg-color,
  .corner__wrap .brk-parallax__bg-gradient,
  .corner__wrap .brk-parallax__bg-gradient-md,
  .circle__wrap .parallax__bg-shape-sm.brk-parallax__bg-color,
  .circle__wrap .brk-parallax__bg-gradient,
  .circle__wrap .brk-parallax__bg-gradient-md,
  .triangle__wrap .parallax__bg-shape-sm.brk-parallax__bg-color,
  .triangle__wrap .brk-parallax__bg-gradient,
  .triangle__wrap .brk-parallax__bg-gradient-md,
  .round__wrap .parallax__bg-shape-sm.brk-parallax__bg-color,
  .round__wrap .brk-parallax__bg-gradient,
  .round__wrap .brk-parallax__bg-gradient-md,
  .wing__wrap .parallax__bg-shape-sm.brk-parallax__bg-color,
  .wing__wrap .brk-parallax__bg-gradient,
  .wing__wrap .brk-parallax__bg-gradient-md {
    opacity: 0.88; }
  .corner__wrap .brk-parallax__bg-gradient,
  .circle__wrap .brk-parallax__bg-gradient,
  .triangle__wrap .brk-parallax__bg-gradient,
  .round__wrap .brk-parallax__bg-gradient,
  .wing__wrap .brk-parallax__bg-gradient {
    background: linear-gradient(to right, var(--brk-base-3), var(--secondary)); }
  .corner__wrap .brk-parallax__bg-gradient-md,
  .circle__wrap .brk-parallax__bg-gradient-md,
  .triangle__wrap .brk-parallax__bg-gradient-md,
  .round__wrap .brk-parallax__bg-gradient-md,
  .wing__wrap .brk-parallax__bg-gradient-md {
    background: linear-gradient(to right, var(--brk-base-3), var(--secondary)); }
    @media screen and (min-width: 576px) {
      .corner__wrap .brk-parallax__bg-gradient-md,
      .circle__wrap .brk-parallax__bg-gradient-md,
      .triangle__wrap .brk-parallax__bg-gradient-md,
      .round__wrap .brk-parallax__bg-gradient-md,
      .wing__wrap .brk-parallax__bg-gradient-md {
        background: none; } }
  .corner__wrap .brk-parallax__bg-color,
  .circle__wrap .brk-parallax__bg-color,
  .triangle__wrap .brk-parallax__bg-color,
  .round__wrap .brk-parallax__bg-color,
  .wing__wrap .brk-parallax__bg-color {
    opacity: 1;
    background: var(--white); }
  .corner__wrap .brk-parallax__bg-color-md,
  .circle__wrap .brk-parallax__bg-color-md,
  .triangle__wrap .brk-parallax__bg-color-md,
  .round__wrap .brk-parallax__bg-color-md,
  .wing__wrap .brk-parallax__bg-color-md {
    background: var(--white); }
    @media screen and (min-width: 576px) {
      .corner__wrap .brk-parallax__bg-color-md,
      .circle__wrap .brk-parallax__bg-color-md,
      .triangle__wrap .brk-parallax__bg-color-md,
      .round__wrap .brk-parallax__bg-color-md,
      .wing__wrap .brk-parallax__bg-color-md {
        background: none; } }
  .corner__wrap.content__side-left,
  .circle__wrap.content__side-left,
  .triangle__wrap.content__side-left,
  .round__wrap.content__side-left,
  .wing__wrap.content__side-left {
    transform: translate(-50%, 0); }
  .corner__wrap.content__side-right,
  .circle__wrap.content__side-right,
  .triangle__wrap.content__side-right,
  .round__wrap.content__side-right,
  .wing__wrap.content__side-right {
    transform: translate(50%, 0); }
  .corner__wrap.in-view.content__side-left, .corner__wrap.in-view.content__side-right,
  .circle__wrap.in-view.content__side-left,
  .circle__wrap.in-view.content__side-right,
  .triangle__wrap.in-view.content__side-left,
  .triangle__wrap.in-view.content__side-right,
  .round__wrap.in-view.content__side-left,
  .round__wrap.in-view.content__side-right,
  .wing__wrap.in-view.content__side-left,
  .wing__wrap.in-view.content__side-right {
    transform: translate(0, 0); }

@media screen and (min-width: 576px) {
  .corner__wrap .parallax__bg-shape-lg {
    opacity: 1; } }

.corner__wrap.content__side-right .parallax__bg-shape-lg {
  left: 50%;
  width: 100vw;
  transform: skew(0) translate(-50%, 0); }
  @media screen and (min-width: 576px) {
    .corner__wrap.content__side-right .parallax__bg-shape-lg {
      left: 40%;
      width: calc(50vw + 200px);
      transform: skew(-15deg); } }
  @media screen and (min-width: 768px) {
    .corner__wrap.content__side-right .parallax__bg-shape-lg {
      left: 50%; } }

.corner__wrap.content__side-left .parallax__bg-shape-lg {
  right: 50%;
  width: 100vw;
  transform: skew(0) translate(50%, 0); }
  @media screen and (min-width: 576px) {
    .corner__wrap.content__side-left .parallax__bg-shape-lg {
      right: 40%;
      width: calc(50vw + 200px);
      transform: skew(15deg); } }
  @media screen and (min-width: 768px) {
    .corner__wrap.content__side-left .parallax__bg-shape-lg {
      right: 50%; } }

.circle__wrap h2 {
  letter-spacing: -1px; }

.circle__wrap .parallax__bg-shape-lg {
  top: 0;
  bottom: 0;
  position: absolute;
  transition: transform 2s ease;
  will-change: transform;
  width: 100vw; }
  @media screen and (min-width: 576px) {
    .circle__wrap .parallax__bg-shape-lg {
      width: 65%;
      top: 50%;
      left: auto;
      bottom: auto;
      border-radius: 50%;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
      transform: translate(0, -50%) scale(2);
      opacity: 0.88; } }
  .circle__wrap .parallax__bg-shape-lg:after {
    content: '';
    position: relative;
    display: block;
    padding-bottom: 100%; }

.circle__wrap.content__side-right .parallax__bg-shape-lg {
  left: 50%;
  right: auto;
  transform: translate(-50%, 0); }
  @media screen and (min-width: 576px) {
    .circle__wrap.content__side-right .parallax__bg-shape-lg {
      left: 47%;
      transform: translate(0, -50%) scale(2); } }

.circle__wrap.content__side-left .parallax__bg-shape-lg {
  right: 50%;
  left: auto;
  transform: translate(50%, 0); }
  @media screen and (min-width: 576px) {
    .circle__wrap.content__side-left .parallax__bg-shape-lg {
      right: 47%;
      transform: translate(0, -50%) scale(2); } }

@media screen and (min-width: 576px) {
  .circle__wrap.in-view .parallax__bg-shape-lg {
    transform: translate(0, -50%) scale(1.5); } }

@media screen and (min-width: 768px) {
  .circle__wrap.in-view .parallax__bg-shape-lg {
    transform: translate(0, -50%) scale(1.1); } }

.triangle__wrap .horiz-line {
  margin: 20px 0; }

.triangle__wrap i.icon {
  vertical-align: baseline; }

.triangle__wrap .parallax__bg-shape-lg {
  position: absolute;
  left: 50%;
  width: 100vw;
  transform: translate(-50%, 0); }
  @media screen and (min-width: 576px) {
    .triangle__wrap .parallax__bg-shape-lg {
      width: calc(50vw + 50px); } }

@media screen and (min-width: 576px) {
  .triangle__wrap.content__side-right .parallax__bg-shape-lg {
    transform: none;
    right: auto;
    left: calc(50% - 50px);
    -webkit-clip-path: polygon(120px 0, 100% 0, 100% 100%, 120px 100%, 0 50%);
            clip-path: polygon(120px 0, 100% 0, 100% 100%, 120px 100%, 0 50%); } }

@media screen and (min-width: 576px) {
  .triangle__wrap.content__side-left .parallax__bg-shape-lg {
    transform: none;
    left: auto;
    right: calc(50% - 50px);
    -webkit-clip-path: polygon(calc(100% - 120px) 0, 100% 50%, calc(100% - 120px) 100%, 0 100%, 0 0);
            clip-path: polygon(calc(100% - 120px) 0, 100% 50%, calc(100% - 120px) 100%, 0 100%, 0 0); } }

.triangle__wrap-double .btn-simple i {
  vertical-align: middle;
  font-size: 24px; }

.triangle__wrap-double .parallax__bg-shape-sm {
  position: absolute;
  display: none;
  height: 100%;
  width: 300px;
  transition: right 2s ease, left 2s ease, tranfrorm 2s ease;
  will-change: right; }
  @media (min-width: 992px) {
    .triangle__wrap-double .parallax__bg-shape-sm {
      display: block; } }

.triangle__wrap-double.content__side-left .parallax__bg-shape-sm {
  left: auto;
  right: calc(-300px - 50vw);
  -webkit-clip-path: polygon(150px 0, 120% 0, 100% 100%, 120px 100%, 0 50%);
          clip-path: polygon(150px 0, 120% 0, 100% 100%, 120px 100%, 0 50%); }

.triangle__wrap-double.content__side-right .parallax__bg-shape-sm {
  left: calc(-300px - 50vw);
  right: auto;
  -webkit-clip-path: polygon(calc(100% - 120px) 0, 100% 50%, calc(100% - 120px) 100%, 0 100%, 0 0);
          clip-path: polygon(calc(100% - 120px) 0, 100% 50%, calc(100% - 120px) 100%, 0 100%, 0 0); }

.triangle__wrap-double.content__side-right.in-view .parallax__bg-shape-sm {
  left: calc(50% - 50vw);
  transform: translate(0, 0); }

.triangle__wrap-double.content__side-left.in-view .parallax__bg-shape-sm {
  right: calc(50% - 50vw);
  transform: translate(0, 0); }

.row.corner__wrap > .after.brk-color {
  background: var(--white); }

.row.corner__wrap-grad > .after.brk-color {
  background: linear-gradient(to right, var(--brk-base-3) 30%, var(--secondary)); }

.row.circle__wrap > .after.brk-color {
  background: var(--white); }

.row.circle__wrap-grad > .after.brk-color {
  background: linear-gradient(to right, var(--brk-base-3), var(--secondary)); }

.row.triangle__wrap > .after.brk-color {
  background: var(--white); }

.row.triangle__wrap-grad > .after.brk-color {
  background: linear-gradient(to right, var(--brk-base-3) 30%, var(--secondary)); }

.round__wrap .text-blue {
  color: #2668d0; }

.round__wrap .divider {
  background: rgba(var(--brand-primary-rgb), 0.2); }

.round__wrap .parallax__bg-shape-lg {
  position: absolute;
  width: 100vw; }
  .round__wrap .parallax__bg-shape-lg svg {
    position: absolute;
    top: 0;
    height: 100%;
    width: auto;
    display: block; }

.round__wrap.content__side-right .parallax__bg-shape-lg {
  left: auto;
  right: 50%;
  transform: translate(50%, 0); }
  @media screen and (min-width: 576px) {
    .round__wrap.content__side-right .parallax__bg-shape-lg {
      right: -50%;
      transform: translate(0, 0); } }
  @media screen and (min-width: 768px) {
    .round__wrap.content__side-right .parallax__bg-shape-lg {
      right: -10%; } }
  .round__wrap.content__side-right .parallax__bg-shape-lg svg {
    right: 0;
    transform: translate(50%, 0); }

.round__wrap.content__side-left .parallax__bg-shape-lg {
  right: auto;
  left: 50%;
  transform: translate(-50%, 0); }
  @media screen and (min-width: 576px) {
    .round__wrap.content__side-left .parallax__bg-shape-lg {
      left: -50%;
      transform: translate(0, 0); } }
  @media screen and (min-width: 768px) {
    .round__wrap.content__side-left .parallax__bg-shape-lg {
      left: -10%; } }
  .round__wrap.content__side-left .parallax__bg-shape-lg svg {
    left: 0;
    transform: translate(-50%, 0); }

.wing__wrap > * {
  z-index: 2;
  position: relative; }

.wing__wrap .countdown {
  padding: 35px 0 45px;
  overflow: hidden; }
  .wing__wrap .countdown .countdown__section {
    border-left: 1px solid #ededed;
    margin: 0;
    width: 33.3%;
    float: left; }
    @media (max-width: 575px) {
      .wing__wrap .countdown .countdown__section {
        border-left: none; } }
    .wing__wrap .countdown .countdown__section:first-child {
      border: none; }
  .wing__wrap .countdown__wrapper {
    position: relative;
    z-index: 1;
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.12); }

.wing__wrap .parallax__bg-shape-lg {
  position: absolute;
  z-index: 1;
  top: auto;
  bottom: 0;
  padding: 0;
  height: 100%;
  width: 100vw;
  left: 50%;
  transform: translate(-50%, 0); }
  @media screen and (min-width: 576px) {
    .wing__wrap .parallax__bg-shape-lg {
      right: auto; } }
  .wing__wrap .parallax__bg-shape-lg svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    min-width: 768px;
    transform: translate(-50%, 0);
    display: none; }
    @media screen and (min-width: 576px) {
      .wing__wrap .parallax__bg-shape-lg svg {
        display: block; } }

.wing__wrap [class*="brk-parallax__bg-gradient"] ~ div h1, .wing__wrap [class*="brk-parallax__bg-gradient"] ~ div h2 {
  color: #fff;
  opacity: 1;
  line-height: 1; }
  .wing__wrap [class*="brk-parallax__bg-gradient"] ~ div h1 .badge, .wing__wrap [class*="brk-parallax__bg-gradient"] ~ div h2 .badge {
    top: -7px;
    margin-right: 10px;
    line-height: 32px;
    position: relative; }

.wing__wrap [class*="brk-parallax__bg-gradient"] ~ div p {
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.6); }

.full-height {
  height: 100vh;
  display: flex;
  align-items: center; }
  .full-height > div {
    flex-direction: column;
    justify-content: center;
    height: auto;
    align-items: flex-start; }
  .full-height.round__wrap.content__side-left .parallax__bg-shape-lg {
    left: 0; }
    @media screen and (min-width: 576px) {
      .full-height.round__wrap.content__side-left .parallax__bg-shape-lg {
        left: -100%; } }
    @media screen and (min-width: 768px) {
      .full-height.round__wrap.content__side-left .parallax__bg-shape-lg {
        left: -80%; } }
    @media screen and (min-width: 992px) {
      .full-height.round__wrap.content__side-left .parallax__bg-shape-lg {
        left: -60%; } }
  .full-height.round__wrap.content__side-right .parallax__bg-shape-lg {
    right: 0; }
    @media screen and (min-width: 576px) {
      .full-height.round__wrap.content__side-right .parallax__bg-shape-lg {
        right: -100%; } }
    @media screen and (min-width: 768px) {
      .full-height.round__wrap.content__side-right .parallax__bg-shape-lg {
        right: -80%; } }
    @media screen and (min-width: 992px) {
      .full-height.round__wrap.content__side-right .parallax__bg-shape-lg {
        right: -60%; } }

.player-yt, .player-vimeo {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }
  .video-background .embed-responsive-16by9 {
    top: 50%;
    position: absolute;
    transform: translate(0, -50%); }
  @media screen and (max-width: 767px) {
    .video-background {
      display: none; } }
  .video-background .vimeo_icon,
  .video-background .ytpicon {
    font-family: "Font Awesome 5 Free";
    font-size: 0 !important;
    text-align: center; }
    .video-background .vimeo_icon:after,
    .video-background .ytpicon:after {
      font: 14px/25px 'Font Awesome 5 Solid'; }
    .video-background .vimeo_icon.vimeo_player_pause:after, .video-background .vimeo_icon.mb_YTPPlaypause:after,
    .video-background .ytpicon.vimeo_player_pause:after,
    .video-background .ytpicon.mb_YTPPlaypause:after {
      content: "\f04b"; }
    .video-background .vimeo_icon.vimeo_player_pause.active:after, .video-background .vimeo_icon.mb_YTPPlaypause.active:after,
    .video-background .ytpicon.vimeo_player_pause.active:after,
    .video-background .ytpicon.mb_YTPPlaypause.active:after {
      content: "\f04c"; }
    .video-background .vimeo_icon.vimeo_player_muteUnmute:after, .video-background .vimeo_icon.mb_YTPMuteUnmute:after,
    .video-background .ytpicon.vimeo_player_muteUnmute:after,
    .video-background .ytpicon.mb_YTPMuteUnmute:after {
      content: "\f026"; }
    .video-background .vimeo_icon.vimeo_player_muteUnmute.active:after, .video-background .vimeo_icon.mb_YTPMuteUnmute.active:after,
    .video-background .ytpicon.vimeo_player_muteUnmute.active:after,
    .video-background .ytpicon.mb_YTPMuteUnmute.active:after {
      content: "\f028"; }
    .video-background .vimeo_icon.mb_YTPUrl:after,
    .video-background .ytpicon.mb_YTPUrl:after {
      content: "\f167";
      font: 27px/20px 'Font Awesome 5 Brands'; }
    .video-background .vimeo_icon.vimeo_fullscreen,
    .video-background .ytpicon.vimeo_fullscreen {
      display: none; }
      .video-background .vimeo_icon.vimeo_fullscreen:after,
      .video-background .ytpicon.vimeo_fullscreen:after {
        content: "\f320"; }
  .video-background .simpleSlider {
    vertical-align: middle; }

.subscribe-form {
  position: relative; }
  .subscribe-form input {
    width: calc(100%);
    height: 54px;
    border-radius: 27px;
    font-size: 0.875rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    padding: 0 50px 0 30px;
    transition: all .4s ease-in-out;
    background-color: rgba(var(--white-rgb), 0.12);
    color: var(--white);
    border: 2px solid rgba(var(--white-rgb), 0.2); }
    .subscribe-form input::-webkit-input-placeholder {
      color: #fff; }
    .subscribe-form input::-moz-placeholder {
      color: #fff; }
    .subscribe-form input:-ms-input-placeholder {
      color: #fff; }
    .subscribe-form input::-ms-input-placeholder {
      color: #fff; }
    .subscribe-form input::placeholder {
      color: #fff; }
  .subscribe-form .btn {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #ffffff;
    transition: .3s ease background; }
    .subscribe-form .btn i {
      color: var(--brand-primary);
      margin-right: 0 !important; }
    .subscribe-form .btn:hover {
      background-color: rgba(255, 255, 255, 0.8); }
